import { Button, DialogContentText, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import create from 'zustand';
import { useChargePoint, WithChargePoint } from '../../wrappers/WithChargePoint';
// import { EventLogComponent } from '../logs/ChargePointLogs';

type Store = {
    open: boolean;
    chargePointId: string;
    show: (chargePointId: string) => void;
    hide: () => void;
};

export const useChargePointEventLogsDialog = create<Store>((set, get) => ({
    open: false,
    chargePointId: '',
    show: (chargePointId: string) => set({ open: true, chargePointId: chargePointId }),
    hide: () => set({ open: false, chargePointId: '' }),
}));

function DialogInternal() {
    const open = useChargePointEventLogsDialog(store => store.open);
    const hide = useChargePointEventLogsDialog(store => store.hide);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const chargePoint = useChargePoint();

    return (
        <Dialog open={open} onClose={hide} maxWidth={'sm'} fullScreen={fullScreen} fullWidth>
            <DialogTitle>OCPP события зарядной станции</DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <DialogContentText sx={{ paddingBottom: 2 }}></DialogContentText>
                {/*<EventLogComponent chargePointId={chargePoint._id} />*/}
            </DialogContent>
            <DialogActions>
                <Button onClick={hide}>Закрыть</Button>
            </DialogActions>
        </Dialog>
    );
}

export function ChargePointEventLogsDialog() {
    const chargePointId = useChargePointEventLogsDialog(store => store.chargePointId);

    return (
        <WithChargePoint id={chargePointId}>
            <DialogInternal />
        </WithChargePoint>
    );
}
